import _events from "events";
import _jsonBuffer from "json-buffer";
var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

const EventEmitter = _events;
const JSONB = _jsonBuffer;

const loadStore = opts => {
  const adapters = {
    redis: "@keyv/redis",
    mongodb: "@keyv/mongo",
    mongo: "@keyv/mongo",
    sqlite: "@keyv/sqlite",
    postgresql: "@keyv/postgres",
    postgres: "@keyv/postgres",
    mysql: "@keyv/mysql"
  };

  if (opts.adapter || opts.uri) {
    const adapter = opts.adapter || /^[^:]*/.exec(opts.uri)[0];
    return new (_nullRequire(adapters[adapter]))(opts);
  }

  return new Map();
};

class Keyv extends EventEmitter {
  constructor(uri, opts) {
    super();
    this.opts = Object.assign({
      namespace: "keyv",
      serialize: JSONB.stringify,
      deserialize: JSONB.parse
    }, typeof uri === "string" ? {
      uri
    } : uri, opts);

    if (!this.opts.store) {
      const adapterOpts = Object.assign({}, this.opts);
      this.opts.store = loadStore(adapterOpts);
    }

    if (typeof this.opts.store.on === "function") {
      this.opts.store.on("error", err => this.emit("error", err));
    }

    this.opts.store.namespace = this.opts.namespace;
  }

  _getKeyPrefix(key) {
    return `${this.opts.namespace}:${key}`;
  }

  get(key) {
    key = this._getKeyPrefix(key);
    const store = this.opts.store;
    return Promise.resolve().then(() => store.get(key)).then(data => {
      data = typeof data === "string" ? this.opts.deserialize(data) : data;

      if (data === undefined) {
        return undefined;
      }

      if (typeof data.expires === "number" && Date.now() > data.expires) {
        this.delete(key);
        return undefined;
      }

      return data.value;
    });
  }

  set(key, value, ttl) {
    key = this._getKeyPrefix(key);

    if (typeof ttl === "undefined") {
      ttl = this.opts.ttl;
    }

    if (ttl === 0) {
      ttl = undefined;
    }

    const store = this.opts.store;
    return Promise.resolve().then(() => {
      const expires = typeof ttl === "number" ? Date.now() + ttl : null;
      value = {
        value,
        expires
      };
      return store.set(key, this.opts.serialize(value), ttl);
    }).then(() => true);
  }

  delete(key) {
    key = this._getKeyPrefix(key);
    const store = this.opts.store;
    return Promise.resolve().then(() => store.delete(key));
  }

  clear() {
    const store = this.opts.store;
    return Promise.resolve().then(() => store.clear());
  }

}

exports = Keyv;
export default exports;